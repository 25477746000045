

/* footer style */
.FooterSection{
    position: relative;
    background-color:  #3b2196; 
    padding-bottom: 1em;
  }
  .CloudShapeImg{
    width: 100%;
  }
  .joinWrap{
    text-align: center;
    position: relative;
  }
  .vixenBtn{
    position:absolute;
    top: 65%;
    width: 100%;
  }
  .copyright{
    color: #ffffff;
    font-size: 18px;
    font-family: 'Montserrat' !important; 
    padding-left: 5px;
    padding-right: 5px;
  }
  .divider{
    margin: 50px 5% 24px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    width: 90%;
  }
  .footerMenuItem{
    color: #ffffff;
    font-size: 18px;
    font-family: 'Montserrat' !important;
  }
  .footerMenu{
    color: #ffffff;
    font-size: 16px;
    font-family: 'Montserrat' !important;
  }
  .icons{
    font-size: 14px !important;
    margin-right: 10px !important;
  }
  .footerMenu:hover{
   cursor: pointer;
   color: #e4caa9;
  }
  .footerWrap{
    text-align: center;
    width: 100%;
  }
  .fIcons{
    /* color: white !important;  */
    color: #e4caa9;
    font-size: 3rem !important;  
    margin-left: 5px !important;
    cursor: pointer;
  }
  .fIcons:hover{ 
    cursor: pointer;
  }
  .itemWrap{
    padding-left: 5%;
  }
  .makeStyles-root-12{
    z-index: 99;
  }
  .copyWrap{
    text-align: center;
  }