.search-scope{
    padding-top: 3em;
}
.search-box{
    width: 100%;
}
.catetory-sel{
    width: 100%;
}
.search-scope .left-scope{ 
    border-right: solid 1px #c7c7c7; 
    padding-right: 1% !important;    
}
.search-scope .left-scope .item-wrap{
    width: 100%;
    padding: 8px;
    background: #e7e7e7;
    border-radius: 10px;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;     
}
.match-scope .item-wrap{
    width: 100%;
    padding: 8px;
    background: #e7e7e7;
    border-radius: 10px;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;     
}
.search-scope .left-scope .item-wrap .item-img{
    width: 100%;
    border-radius: 10px;
}
.search-scope .right-scope{
    min-height: 85vh;
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
    align-items: center !important
}
.folder-icon{
    font-size: 50px !important;
    color: #ebb438 !important;;
}
.folder-badge{
    margin-top: 10px;
    margin-right: 5px;
}
.primary-tm{
    margin-top: 10px !important;
}
.ml-10{
   margin-left: 10px !important;
}
.primary-color{
    background: #007fff !important;
    color: white !important;
    font-weight: 600 !important;
    font-family: 'Montserrat' !important;
}
.disable-color{
    background: #6d6d6d !important;
    color: white !important;
    font-weight: 600 !important;
    font-family: 'Montserrat' !important;
}

.primary-color-btn-icon{
    color: #007fff !important;
    font-size: 40px !important;
}
.grey-color-btn-icon{
    color: #5f5f5f !important;
    font-size: 40px !important;
}
.primary-width{
    min-width: 120px !important
}
.right-scope .item-area{
     display: flex;
     flex-direction: column;
     justify-content: space-between; 
}
.right-scope .item-area-ai{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.btn-area{
    margin-bottom: 40px;
    width: 100%;
    text-align: center;
    padding-top: 40px;
    border-top: solid 1px #8080807d;
}
.btn-down-area{
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
    padding-top: 10px;
    border-top: solid 1px #8080807d;
}
.item-label{
    font-size: 16px;
    font-family: 'Montserrat' !important; 
    font-weight: 600;
}
.item-des-wrap{
    padding: 3%;
}
.item-img-wrap{
    position: relative;
}
.item-img-zoom{
    width: 100%;
    height: auto;
}
.hover-wrap{
    position: absolute;
    background: transparent;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    top: 0; 
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;
}
.hover-wrap:hover{
    background: #fdfbfb71 !important;
}
.item-des-wrap .btn-wrap{
    display: flex;
    align-items: center;
    justify-content: center;
}
.right-scope .image-wrap{
    position: relative;
    margin-right: 15px;
}
.right-scope .image-control-btn{
    position: absolute;
    top: 0px;
    right: -10px;
}
.right-scope .close-icon{
    background: white;
    border-radius: 30px;
    color: #3f76af;
}
.right-scope .main-image-item{
    width: 100%;
}
.ai-c-label{
    margin-bottom: 0;
    margin-left: 10px;
}
.align-r{
    text-align: right !important;
}
iframe{
    width: 100%;
    height: 100%;
}
.pdf-wrap{
    height: 90vh;
    width: 100%;
}
.mb-20{
    margin-bottom: 20px !important;
}
.canvas-wrap{
    display: none;
}
@media only screen and (max-width: 900px) {
    .search-scope .left-scope{
        border: none !important;
    }
    .right-scope .item-area{ 
        height: auto;
   }
}