
.recommend .desGroup{
    text-align: left;
  }
  .recommend .btnGroup{
    text-align: right;
  }
  .recommend .profileLink{
    color: #000b8b !important;
  }
  .recommend .profileLink:hover{
    cursor: pointer; 
  }
  .recommend .card{
    padding: 2%;
  }
  .recommend h4{
    margin: 0 !important;
  }
  .recommendTitle{
    font-family: 'Montserrat' !important;
  }
  .recommend .profileWrap{
    margin-top: 20px;
    margin-bottom: 15px;
    overflow-x: hidden;
    display: flex;
    overflow: auto;  
  } 
  .recommend .profileWrap::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }  
  .recommend .profileWrap::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  } 
  .recommend .profileWrap::-webkit-scrollbar-thumb {
    background: #9230f9; 
    border-radius: 10px;
  } 
  .recommend .profileWrap::-webkit-scrollbar-thumb:hover {
    background: #9230f9; 
  }
  
  .recommend .profileImg{
    max-width: 500px;
    min-width: 300px;
    border-radius: 20px;
  }
  .recommend .profileImgWrap{
    display: inline-block;
    padding: 10px;
  }
  .recommend-item:hover{
    cursor: pointer;
  }
  .recommend .profileWrap .item{
    position: relative;
  } 

  .recommend .profileWrap .cover{
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #f9f9f969;
    display: block;
    top: 0;
  }
  .btn-view{
    float: right;
    margin-right: 20px;
    margin-top: 15px;
  }
  .btn-view:hover{
    cursor: pointer;
  }