.App {
  text-align: center;
  font-family: "Montserrat" !important;
}

body,
html {
  font-family: "Montserrat" !important;
}

.erp-flex-v-c {
  display: flex !important;
  align-items: center;
}

.erp-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.erp-right {
  display: flex;
  align-items: center;
  justify-content: end;
}

.erp-ml-5 {
  margin-left: 5px !important;
}

.erp-ml-10 {
  margin-left: 10px !important;
}

.erp-mt-6 {
  margin-top: 6px !important;
}

.erp-mt-10 {
  margin-top: 10px !important;
}

.erp-mt-20 {
  margin-top: 20px !important;
}

.erp-mt-40 {
  margin-top: 40px !important;
}

.erp-pt-10 {
  padding-top: 10px !important;
}

.erp-w-100 {
  width: 100%;
}

.erp-mr-20 {
  margin-right: 20px !important;
}

.erp-mr-10 {
  margin-right: 10px !important;
}

.erp-w-50 {
  width: 50%;
}

.erp-pr-10 {
  padding-right: 10px !important;
}

.erp-font-s {
  font-size: 14px;
  color: #484848;
}

.erp-m-0 {
  margin: 0 !important;
}

.erp-float-btn {
  border-radius: 99px !important;
  background: #007fff !important;
  color: white !important;
  width: 40px !important;
  height: 40px !important;
}

.erp-pb-s-btn {
  background: #007fff !important;
  color: white !important;
  padding: 0px 4px !important;
  margin-left: 5px !important;
}

.erp-primary-color-btn-icon {
  color: #007fff !important;
  font-size: 40px !important;
  cursor: pointer;
}

.erp-primary-color-btn-icon-m {
  color: #007fff !important;
  font-size: 30px !important;
  cursor: pointer;
}

.erp-delete-color-btn-icon-m {
  color: #57595a !important;
  font-size: 30px !important;
  cursor: pointer;
}

.erp-green-color-btn-icon {
  color: #007c0a !important;
  font-size: 40px !important;
}

.erp-icon-btn-del {
  font-size: 16px !important;
}

.erp-pb-color {
  background-color: #1976D2 !important;
  color: white !important;
}

.erp-gb-color {
  background-color: #666666 !important;
  color: white !important;
}

.erp-bb-color {
  background-color: #202020 !important;
  color: white !important;
}

.erp-grb-color {
  background-color: #189100 !important;
  color: white !important;
}

.erp-orb-color {
  background-color: #dd7600 !important;
  color: white !important;
}

.erp-btn-padding {
  padding: 2px 10px !important;
  font-size: 0.75rem !important;
}

.erp-sel {
  display: block !important;
}

.erp-thumnails {
  max-width: 80px;
}

.MuiSelect-select {
  margin-left: 10px !important;
}

.erp-input {
  max-width: 100px;
}

.erp-divider-m {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.erp-divider {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.erp-sel-w {
  width: 100px !important;
  text-align: center;
}
.erp-sel-w-r {
  width: 100px !important;
  text-align: right;
}

.each-slide {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center; 
}
.each-slide img{
  max-width: 720px !important;
}
.erp-set-model{
  background: #007fff;
  padding: 5px;
  border-radius: 5px;
  color: white;
  margin-right: 5px; 
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes changewidth {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.1);
  }
}

.heartImg {
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes changeScale {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.02);
  }
}


.list-item {
  cursor: pointer;
}

.a-center {
  text-align: center;
}

.a-right {
  text-align: right;
}

.a-left {
  text-align: left;
}

.in-row {
  display: flex;
  justify-content: start;
}

.ml {
  margin-left: 10px;
}

.canvas-wrap {
  position: relative;
  padding: 0 !important;
}

.hidden-sec {
  display: none;
}

#myCanvas {
  cursor: crosshair;
  position: relative;
}

#zoomCanvas {
  position: relative;
}



/* Sign in & up & pass page style */
.sign_in_logo {
  max-width: 160px;
  margin-bottom: 50px;
}

.vixen_or_wrap {
  margin-top: 30px;
}

.vixen_or {
  content: "";
  height: 1px;
  width: 100%;
  background: #dddce7;
  left: 0px;
  top: 50%;
  z-index: 1;
  margin-top: 10px !important;
}

.vixen_p {
  text-align: center;
}

.have_account:hover {
  cursor: pointer !important;
}

.forget_from {
  width: 100%;
  max-width: 500px;
}

.sign_in_form {
  max-width: 500px;
}




@media only screen and (max-width: 768px) {

  .ContactSection .formWrap {
    padding-left: 12% !important;
  }

}

@media only screen and (max-width: 600px) {
  .ContactSection .contentWrap {
    padding-left: 1%;
  }
}

@media only screen and (max-width: 560px) {

  .ContactSection .contentWrap {
    padding-left: 1%;
  }


}

@media only screen and (max-width: 480px) {

  .ContactSection .formWrap {
    padding-left: 6% !important;
  }

}

@media only screen and (max-width: 400px) {}

@media only screen and (max-width: 340px) {}