.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}
.App-link {
    color: #61dafb;
}
.makeStyles-root-10{
    z-index: 9;
}   
.logoWrap{
    position: relative; 
    min-width: 95px;
    min-height: 70px;
    padding-top: 10px;  
    padding-left: 15px;
}
.logoWrap:hover{
    cursor: pointer;
}
.headerWrap{
    position: 'fixed';
    width:'100%';
    z-index: 99;
}
.MuiAppBar-colorPrimary{
    background-color: #1f6cac !important;
}

.cart-btn{
    color: whitesmoke;
    font-size: 26px;
}
  