
  .summary .summaryTitle{
    font-family: 'Montserrat' !important; 
    margin-right: 20px;
    display: inline-block;
  }
  .summary .mySummaryContents{
    color: #3c3c3c !important;
    font-size: 16px;
  }
  .summary .card{
    padding: 2%;
    min-height: 300px;
  }
  .summary .summaryDetailWrap{
    display: flex;
    padding: 5px;
  } 
  .summary .summaryIcon{
   display: inline-block;
   font-size: 30px !important;
   margin-right : 10px;
   margin-left:  5%;
   margin-top: auto;
   margin-bottom: auto;
   color: #9230f9;
  }
  .summary .summaryDetail{
   display: inline-block;
   min-width: 250px !important;
   width: 100%;
  }
  .summary .summaryDetail .MuiOutlinedInput-root{  
    min-width: 250px !important;
    width: 100%;
  }
  .summary .css-1kty9di-MuiFormLabel-root-MuiInputLabel-root{
    background-color: #fafafa !important; 
  }
  .summary .summaryDetail{
    font-family: "Montserrat" !important;
  }
  .myFont .MuiSelect-select, .MuiSelect-nativeInput, .MuiInputLabel-root, .MuiInputBase-input{
    font-family: "Montserrat" !important;
  }
  .my-summary{
    width: 100%;
    margin-top: 5px !important; 
  }
  .gallery-img{
    width: 50%;
    padding: 1%;
  }
  .gallery-wrap{
    padding: 2%;
  }
  .profileImgWrap{
    display: inline-block;
    padding: 10px;
  }
  .profileImg{
    max-width: 300px; 
    max-height: 300px;
    border-radius: 20px;
  }
  .profileWrap{
    margin-top: 20px;
    margin-bottom: 15px;
    overflow-x: hidden;
    display: flex;
    overflow: auto;  
  } 
  .profileWrap::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }  
  .profileWrap::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  } 
  .profileWrap::-webkit-scrollbar-thumb {
    background: #9230f9; 
    border-radius: 10px;
  } 
  .profileWrap::-webkit-scrollbar-thumb:hover {
    background: #9230f9; 
  }
  .button-group{
    display: inline-block;
  }
  .summary-group{
    margin-top: 30px !important;
  }
  .profile-button{
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    text-align: right;
  }
  .profile-button:hover{
    background-color: #726f6f33;
    cursor: pointer;
 
  }
  .profile-button .btn-delete{
    margin-right: 10px; 
    position: relative;
    top: 10px; 
  }
  .profile-item{
    position: relative;
    margin-bottom: 10px;
  }
  .summaryLikeWrap{
    display: flex;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    padding: 2px;
    border-color: darkgray;
    overflow-x: scroll;
    margin-left: 65px;
    margin-right: 5px;
  }
  .summaryLikeWrap .item{
    border-style: solid;
    border-color: darkgray;
    border-width: 1px;
    border-radius: 5px;
    padding: 5px;
    margin-right: 2px;
    font-size: 16px !important;
  }
  .summaryLikeWrap:hover{
    cursor: pointer;
  }
  .summaryLikeWrap::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }  
  .summaryLikeWrap::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  } 
  .summaryLikeWrap::-webkit-scrollbar-thumb {
    background: #9230f9; 
    border-radius: 10px;
  } 
  .summaryLikeWrap::-webkit-scrollbar-thumb:hover {
    background: #9230f9; 
  }
  .token-name{
    width: 70%;
  }
  .token-value{
    width: auto;
    margin-left: 2px !important;
  }
  .add-btn{
    font-weight: 700 !important;
    margin-left: 2px !important;
  }
  .like-item .del-icon{
    font-size: 20px;
    margin-left: 2px;
  }
  .like-item{
    display: flex;
  }
  .ownToken{
    margin-top: 5px;
  }