.scope-wrap{
    height: auto;
    border-radius: 4px;
    -webkit-appearance: initial;
    border: 1px solid lightgray; 
}
.scope-wrap .tag-wrap{
    margin: 0;
    padding: 0;
    list-style-type: none; 
}
.scope-wrap .tag-item{
    margin: 2px;
    padding: 0 10px;
    display: inline-block;
    float: left;  
    line-height: 25px;
    border: 1px solid #acacac;
    border-radius: 3px;
}
.scope-wrap .del-icon{
    width: 15px !important;
    color: #2c73cb;
}
.scope-wrap .icon-btn{
    padding: 3px !important;
    margin-top: -1px !important;
}
.scope-wrap .icon-btn:hover{
    cursor: pointer;
}
.scope-wrap .tag-input{
    margin: 2px;
}
.scope-wrap .tag-input .MuiInput-root{
    width: 120px !important;
}
.scope-wrap .tag-input .MuiInput-root::before{
    border-width: 1px !important;
}
.profile-container{
    padding: 3%; 
}
.scope-part{
    margin-top: 0 !important;
}
.scope-title{
    margin: 0 !important;
    font-size: 16px;
}
.category-wrap{
    padding: 30px;
    border-radius: 10px;
    background-color: #fbfbfb;
    box-shadow: 0.872px 9.962px 20px 0px rgb(12 78 165 / 30%) !important;
}
.save-btn{ 
    background-image: linear-gradient(166deg, #103b97 0%, #1162ad 100%) !important;
    -webkit-box-shadow: 0.872px 9.962px 20px 0px rgb(12 78 165 / 30%) !important;
    box-shadow: 0.872px 9.962px 20px 0px rgb(12 78 165 / 30%) !important; 
    font-weight: 500 !important;
    color: #ffffff !important;
    border-radius: 50px !important;
    display:  inline-block !important;
    border: 0px !important;
    cursor: pointer !important;  
    font-family: 'Montserrat' !important;  
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin-left: 15px !important;
    height: 30px;
    margin-top: auto !important;
    margin-bottom: auto !important;
    min-width: 30px !important;
}
.subtitle-wrap{
    display: flex;
}
.wrap-center{
    align-items: center;
    display: flex;
    justify-content: center;
}
.my-font-b{
    font-family: 'Montserrat' !important; 
    font-weight: 600 !important;
}
.my-font-l{
    font-family: 'Montserrat' !important; 
    font-weight: 600 !important;
}
.row-wrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.my-icon{
    font-size: 16px !important; 
    cursor: pointer;
    margin-left: 10px; 
}
.sample-scope .row-wrap-g{
    display: flex;
    align-items: center; 
}
.sample-scope .tag-input{
    margin-left: 20px;
}
.sample-scope .labels{
    font-size: 16px;
    font-family: 'Montserrat' !important; 
    font-weight: 600;
}
.sample-scope .save-btn{ 
    background-image: linear-gradient(166deg, #103b97 0%, #1162ad 100%) !important;
    -webkit-box-shadow: 0.872px 9.962px 20px 0px rgb(12 78 165 / 30%) !important;
    box-shadow: 0.872px 9.962px 20px 0px rgb(12 78 165 / 30%) !important; 
    font-weight: 500 !important;
    color: #ffffff !important;
    border-radius: 50px !important;
    display:  inline-block !important;
    border: 0px !important;
    cursor: pointer !important;  
    font-family: 'Montserrat' !important;  
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin-left: 15px !important;
    height: 30px;
    margin-top: auto !important;
    margin-bottom: auto !important;
    min-width: 70px !important;
}
.sample-scope .category-section{
    min-height: 100px !important;
}
.sample-scope .main-img-section{
    min-height: 150px !important;
}
.sample-scope .sub-img-section{
    min-height: 150px !important;
}
.sample-scope .main-image-item{
    max-width: 100px;
}
.sample-scope .sub-image-item{
    max-width: 100px;
}
.primary-color-btn-icon{
    color: #007fff !important;
    font-size: 40px !important;
}
.sample-scope .image-wrap{
    position: relative;
    margin-right: 15px;
}
.sample-scope .image-control-btn{
    position: absolute;
    top: -10px;
    right: -10px;
}
.sample-scope .close-icon{
    background: white;
    border-radius: 30px;
    color: #3f76af;
}
.sample-scope .over-scroll{
    overflow-x: scroll;
}
.sample-scope .over-scroll:hover{
    cursor: pointer;
}
.sample-scope .over-scroll::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}  
.sample-scope .over-scroll::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px grey;  */
    border-radius: 10px;
} 
.sample-scope .over-scroll::-webkit-scrollbar-thumb {
    background: #3f76af; 
    border-radius: 10px;
} 
.sample-scope .over-scroll::-webkit-scrollbar-thumb:hover {
    background: #3f76af; 
}
.sample-scope .align-right{
    justify-content: end;
}
.gap-right{
    margin-right: 16px !important;
}
.check-box-wrap{ 
    margin-left: 0 !important;
    margin-right: 30px !important;
    margin-top: 10px;
}
.my-divider{
    margin-top: 10px !important;
    margin-bottom: 20px !important;
}
.check-label{
    margin: 0 !important;
}
.primary-color{
    background: #007fff !important;
    color: white !important;
    font-weight: 600 !important;
    font-family: 'Montserrat' !important;
}
.checked-item{
    padding: 2px 9px;
    background: #666666;
    border-radius: 5px;
    margin-right: 5px;
    color: white;
}
.warn-msg{
    color: red;
    font-size: 14px;
    font-weight: 700;
}
.item-wrap{
    width: 100%;
    padding: 8px;
    background: #e7e7e7;
    border-radius: 10px;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;     
}
.item-img-wrap{
    position: relative;
}
.item-img{
    width: 100%;
    border-radius: 10px;
}
.item-des-wrap{
    padding: 3%;
}
.item-label{
    font-size: 16px;
    font-family: 'Montserrat' !important; 
    font-weight: 600;
    line-height: initial;
}
.btn-wrap{
    display: flex;
    align-items: center;
    justify-content: center;
}
.primary-color-btn-icon{
    color: #007fff !important;
    font-size: 40px !important;
}