.profileTitle{
    color: white;
    margin: 0;
    font-size: xxx-large; 
    font-family: 'Montserrat' !important;
  }
  .btnWrap{
    display: inline-block;
    text-align: center;
    width: 100%;
  }
  .btnWrap:hover{
    cursor: pointer;
  }
  .btnWrap .bannerBtn{
    font-size:70px ; 
    color: white;
  }
  .btnDescription{
    color: #ffffff;
    font-size: 14px;
    font-family: 'Montserrat' !important;
  }
  .passBtn{
    color: #3c3c3c !important;
    font-weight: 600 !important;
    border-radius: 26px !important;
    margin-right: 5px !important;
    border-width: 2px !important;
    border-color: #3c3c3c !important;
    font-family: 'Montserrat' !important;
  }
  .likeBtn{
    color: #fb3a70 !important;
    font-weight: 600 !important;
    border-radius: 26px !important;
    margin-right: 5px !important;
    border-width: 2px !important;
    border-color: #fb3a70 !important;
    font-family: 'Montserrat' !important;
  }
  .superBtn{
    color:white !important;
    font-weight: 600 !important;
    border-radius: 26px !important;
    margin-right: 0px !important;
    border-width: 2px !important;
    border-color: #9230f9 !important;
    background-image: linear-gradient(166deg, #4414ff 0%, #b23fff 100%) !important;
    font-family: 'Montserrat' !important;
  }