/* user list */
.user-scope{
    padding: 2%;
    min-width: 900px;
}
.primary-color-fill{
    background: #007fff !important;
    color: white !important;
    font-weight: 600 !important;
    font-family: 'Montserrat' !important;
}
.primary-color-outline{
    background: white !important;
    color: #007fff !important;
    font-weight: 600 !important;
    font-family: 'Montserrat' !important;
}
.primary-ml{
    margin-left: 10px !important;
}
.user-scope .header-wrap{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #00000042;
    margin-top: 40px;
    margin-bottom: 20px;
}
.user-scope .table-row{
    display: flex;
    width: 100%;
    border-bottom: 1px solid #00000042;
}
.user-scope .cell-f{
    width: 25%;
}
.user-scope .cell-s{
    width: 30%;
}
.user-scope .cell-t{
    width: 45%;
}
.user-scope .table-cell{
    text-align: center;
    margin-top: 15px;
    padding-top: 10px;
}
.user-scope .check-row{
    margin-right: 10px;
}
.info-wrap{
    width: 100%;
    padding-top: 10px !important;
    display: flex;
    justify-content: space-between;
}
.user-scope .diag-cont{
    width: 100%;
}
.divider-wrap{
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}
.user-scope .divider-m{
   
}
.btn-primary-mw{
    min-width: 120px !important;
}
/* user list end */